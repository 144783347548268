import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT, AUTH_URL} from "../api-config";
import {authCheck} from "./appLoading";
import _ from "lodash";

const APP_URL = API_ROOT + '/api/management/in-app-response';
export function approveApplication(applicationId, userLoggedInIdentityNumber, applicationType, onProceed = null) {
    return async (dispatch) => {
        try { 
            const appApprove = {
                userLoggedInIdentityNumber : userLoggedInIdentityNumber,
                appResponse : applicationId + ' ' + 'Yes',
                applicationId : applicationId 
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.post(APP_URL, appApprove).then(response => response.data);
            onProceed && onProceed();
            if (responseData.success) {
                const applications = _.reverse(_.sortBy(responseData.data, application => application.id));
                dispatch({type: types.SAVE_USER_APPLICATIONS_SUCCESS, applications, errorMessage: null});
                onProceed && onProceed();
            } else {
                console.log("user.loadUserApplications.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't retrieve your applications."});
            }
        } catch (error) {
            console.log("user.loadUserApplications.error ===>", error);
            authCheck(dispatch,error);
        }
    };
}

export function rejectApplication(applicationId, userLoggedInIdentityNumber, applicationType, onProceed = null) {
    
    return async (dispatch) => {
        try {
            const appReject = {
                userLoggedInIdentityNumber : userLoggedInIdentityNumber,
                appResponse : applicationId + ' ' + 'No',
                applicationId : applicationId 
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.post(APP_URL, appReject).then(response => response.data);
            onProceed && onProceed();
            if (responseData.success) {
                const applications = _.reverse(_.sortBy(responseData.data, application => application.id));
                dispatch({type: types.SAVE_USER_APPLICATIONS_SUCCESS, applications, errorMessage: null});
                onProceed && onProceed();
            } else {
                console.log("user.loadUserApplications.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't retrieve your applications."});
            }
        } catch (error) {
            console.log("user.loadUserApplications.error ===>", error);
            authCheck(dispatch,error);
        }
    };
}