import React from 'react';
import _ from 'lodash'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactLoading from "react-loading";

import '../styles/App.scss';

import * as actions from '../actions/product';
import {downloadReport} from '../actions/reports'
import {loadSelectedChequeApplication, loadUserApplications} from '../actions/user';
import {menuRoutes, newAccountOpeningRoutes} from '../routers/routes';
import ProductTile from './products/ProductTile';
import Popup from './Popup';
import Application from './dashboard/Application';
import PandingApplication from './dashboard/PandingApplication';
import {ListSearchComponent} from './SearchComponent';
import ImpersonationRequiredMessage from './delegation/ImpersonationRequiredMessage';
import BusinessAccountInfo from './products/info/BusinessAccountInfo';
import GlobalPaymentsTrackingInfo from './products/info/GlobalPaymentsTrackingInfo';
import {fetchSelectedOnboardRequest} from '../actions/entity';
import {fetchSelectedCustodyRequest} from '../actions/custody';
import {fetchSelectedKYCRequest} from '../actions/kycMaintenance';
import GlobalMarketAccountInfo from './products/info/GlobalMarketAccountInfo';
import KycReviewInfo from './products/info/KycReviewInfo';
import {fetchSelectedBolOnboardRequest} from '../actions/businessOnline';
import {fetchSelectedTPFARequest} from '../actions/tpfaActions';
import {trackData} from "../actions/analytics";
import {getSignatoryApplication, getSignatoryApplicationsDashboard} from '../actions/signatoryActions';
import Modal from "react-bootstrap/lib/Modal";
import {fetchSelectedCashManagedFundRequest} from "../actions/cashManagedFund";
import {fetchSelectedCustodyManagedFundRequest} from "../actions/custodyManagedFund";
import {fetchSelectedCashAndCustodyManagedFundRequest} from "../actions/cashAndCustodyManagedFund";
import {fetchSelectedElectronicIndemnityRequest} from "../actions/electronicIndemnity";
import {fetchShariahBusinessCurrentAccountRequest} from "../actions/shariahBca";
import {fetchSavingsAccountRequest} from '../actions/savingsAccount';
import {loadImportantNotices} from '../actions/importantNotice';
import {approveApplication, rejectApplication} from '../actions/pendingApproval';

const STATUS_FORMATTED = {
    'REJECTED': 'Rejected',
    'PENDING_APPROVAL': 'Pending Approval',
    'CONCLUDED': 'Concluded',
    'OPEN': 'Open'
};

const APPLICATION_TYPE_FORMATTED = {
    'CHEQUE_ACCOUNT': 'Cheque Account',
    'ENTITY_ONBOARD': 'Entity Onboard',
    'CUSTODY_ACCOUNT': 'Custody Account',
    'GLOBAL_MARKET': 'Global Market Account',
    'EBTC': 'EBTC',
    'KYC_REVIEW': 'KYC Review',
    'BOL_ONBOARD': 'Business  Online Profile',
    'TPFA_ACCOUNTS': 'TPFA Account Application',
    'SIGNATORY_MAINTENANCE': 'Signatory Maintenance',
    'CASH_MANAGED_FUND': 'Managed Fund-Cash Account',
    'CUSTODY_MANAGED_FUND': 'Managed Fund-Custody Account',
    'CASH_CUSTODY_MANAGED_FUND': 'Managed Fund-Cash & Custody Account',
    'ELECTRONIC_INDEMNITY': 'Electronic Indemnity',
    'SHARIAH':'Shariah Business Current Account',
    'SAVINGS': 'Savings Account'
};

const STAFF_PERMISSION = {
    provLDAP: "PROV_LDAP"
};

const MORE_INFO = {
    "Business Current Account": "businessAccount",
    "KYC Review": "KYC Review",
    globalMarket: "globalMarket",
    gpi: "gpi",
    
};

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataPending: true,
            isFetchingApplications: false,
            isDoneFetchingApplications: false,
            termsAccepted: false,
            filteredApplications: null,
            pendingFilteredApplications:null,
            isSBStaff: false,
            noProductAccess: false,
            showSBStaffUserMessage: false,
            onehubBanner: false,
            signatoryData: null,
            moreInfo: {
                ["Business Current Account"]: false,
                [MORE_INFO.gpi]: false,
                [MORE_INFO.globalMarket]: false,
            }
        }
    };

    componentWillMount() {
        this._loadProducts();
        this._loadApplications();
        this._loadSignatoriesApplications();
    }

    componentDidMount() {
        this.props.actions.trackData("pageName", "Home Page");
        this._isMounted = true;
        this._isMountedPandingApplication = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this._isMountedPandingApplication = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.user) {
            const {user} = nextProps;
            const isSBStaff = _.find(user && user.permissions, perm => (perm === STAFF_PERMISSION.provLDAP));
            this._isMounted && this.setState({isSBStaff: isSBStaff && true});
        }
    }
    
    handleOnContinueApplication = (event, applicationId, applicationType, onFinish) => {
        const {actions, history} = this.props;
        switch (applicationType) {
            case 'CHEQUE_ACCOUNT':
                actions.loadSelectedChequeApplication(applicationId, history, onFinish);
                break;
            case 'ENTITY_ONBOARD':
                actions.fetchSelectedOnboardRequest(applicationId, history, onFinish);
                break;
            case 'CUSTODY_ACCOUNT':
                actions.fetchSelectedCustodyRequest(applicationId, history, onFinish);
                break;
            case 'KYC_REVIEW':
                actions.fetchSelectedKYCRequest(applicationId, history);
                break;
            case 'BOL_ONBOARD':
                actions.fetchSelectedBolOnboardRequest(applicationId, history, onFinish);
                break;
            case 'TPFA_ACCOUNTS':
                actions.fetchSelectedTPFARequest(applicationId, history);
                break;
            case 'GLOBAL_MARKET':
                actions.fetchSelectedGlobalMarketRequest(applicationId, history, onFinish);
                break;
            case 'SIGNATORY_MAINTENANCE':
                actions.getSignatoryApplication(applicationId, history)
                break;
            case 'CASH_MANAGED_FUND':
                actions.fetchSelectedCashManagedFundRequest(applicationId, history, onFinish);
                break;
            case 'CUSTODY_MANAGED_FUND':
                actions.fetchSelectedCustodyManagedFundRequest(applicationId, history, onFinish);
                break;
            case 'CASH_CUSTODY_MANAGED_FUND':
                actions.fetchSelectedCashAndCustodyManagedFundRequest(applicationId, history, onFinish);
                break;
            case 'ELECTRONIC_INDEMNITY':
                actions.fetchSelectedElectronicIndemnityRequest(applicationId, history, onFinish);
                break;
            case 'SHARIAH':
                actions.fetchShariahBusinessCurrentAccountRequest(applicationId, history, onFinish);
                break;
            case 'SAVINGS':
                actions.fetchSavingsAccountRequest(applicationId, history, onFinish);
                break;
        }

    };

    handleAcceptApplication = (event, applicationId, userLoggedInIdentityNumber) =>{
        const {actions, history} = this.props;
        actions.approveApplication(applicationId, userLoggedInIdentityNumber);
        console.log(applicationId, userLoggedInIdentityNumber);
    }

    handleRejectApplication = (event, applicationId, userLoggedInIdentityNumber) =>{
        const {actions, history} = this.props;
        actions.rejectApplication(applicationId, userLoggedInIdentityNumber);
        console.log(applicationId, userLoggedInIdentityNumber);
    }


    _loadApplications = () => {
        const {actions} = this.props;
        this.setState({isFetchingApplications: true});
        const onComplete = () => this._isMounted && this.setState({
            isFetchingApplications: false,
            isDoneFetchingApplications: true
        });
        actions.loadUserApplications(onComplete);
    };

    _loadSignatoriesApplications = () => {
        const {actions} = this.props;
        this.setState({isFetchingApplications: true});
        const onComplete = () => this._isMounted && this.setState({
            isFetchingApplications: false,
            isDoneFetchingApplications: true
        });
        actions.getSignatoryApplicationsDashboard(onComplete);
    };

    _loadProducts = () => {
        const {actions} = this.props;
        const onComplete = () => this._isMounted && this.setState({dataPending: false});
        actions.loadProducts(onComplete);
    };

    handleToggleSBStaffUserMessage = () => {
        this._isMounted && this.setState(prevState => ({showSBStaffUserMessage: !prevState.showSBStaffUserMessage}))
    };

    handleAllowUserToProceed = () => {
        const {impersonation} = this.props;
        if(_.isEmpty(impersonation)){
            return true;
        }
        const {isSBStaff} = this.state;
        const canProceed = isSBStaff ? impersonation && (impersonation.username || impersonation.firstName) : true;
        if (isSBStaff && !canProceed) {
            this.handleToggleSBStaffUserMessage();
        }
        return canProceed;
    };

    handleAllowedProduct = (productId) => {
        const {impersonation} = this.props;
        const {isSBStaff} = this.state;
        if (isSBStaff || _.isEmpty(impersonation)) {
            return true;
        }
        // if (!impersonation.products.includes(productId)){
        //     this.setState({noProductAccess: true})
        //     return false;
        // } else {
            return true;
        // }
    }

    viewMenuOption = (event, path) => {
        event.preventDefault();
        if (this.handleAllowUserToProceed()) {
            const {actions, history} = this.props;
            actions.viewMenuOption(history, path);
        }
    };

    handleOnSelectProduct = (productId, productRoute) => {
        if (this.handleAllowUserToProceed() && this.handleAllowedProduct(productId)) {
            const {actions, history} = this.props;
            const onProceed = () => history && history.push(productRoute);
            actions.addProduct(productId, onProceed);
        }
    };

    handleAcceptTsAndCs = ({target}) => {
        this._isMounted && ({termsAccepted: target.checked})
    };

    handleDownloadReport = (reportName, requestBody, onComplete = null) => {
        const {actions} = this.props;
        actions.downloadReport(reportName, requestBody, onComplete, true)
    };

    onHandleFilterApplications = (searchTerm) => {
        const {applications} = this.props;
        let filteredApplications = null;
        if (searchTerm) {
            filteredApplications = _.filter(applications, ({id, applicationStatus}) => (
                    (id && id.toString().includes(searchTerm)) ||
                    (applicationStatus && applicationStatus.applicationOutcome && applicationStatus.applicationOutcome.toLowerCase().includes(searchTerm.toLowerCase()))
                )
            )
        }
        this._isMounted && this.setState({filteredApplications, searchTerm});
    };

    onHandleFilterPendingForApplications = (searchTerm) => {
        const {applications} = this.props;
        let pendingFilteredApplications = null;
        if (searchTerm) {
            pendingFilteredApplications = _.filter(applications, ({id, applicationStatus}) => (
                    (id && id.toString().includes(searchTerm)) ||
                    (applicationStatus && applicationStatus.applicationOutcome && applicationStatus.applicationOutcome.toLowerCase().includes(searchTerm.toLowerCase()))
                )
            )
        }
        this._isMounted && this.setState({pendingFilteredApplications, searchTerm});
    };

    onToggleMoreInfoPopUps = (attribute) => {
        this._isMounted && this.setState(prevState => {
            const value = !(prevState.moreInfo && prevState.moreInfo[attribute]);
            const moreInfo = _.extend({}, prevState.moreInfo, {[attribute]: value});
            return {moreInfo}
        });
    };

    _renderHeader() {
        const { importantNotices } = this.props;
        return (
            <div className="row" style={{margin:0}}>
                {importantNotices
                    .filter(notice => notice.alertType === 'IMPORTANT_NOTICE' && notice.active == true)
                    .map((notice, index) => (
                        <div key={index} className="col-lg-12 col-md-12 col-sm-12" style={{padding:0}}>
                            <div className="flexRow" style={{ width: "100%", padding: 0, margin: 0 }}>
                                <dl className="dl-horizontal" style={{ textAlign: 'justify', margin: 0 }}>
                                    <dt style={{ paddingTop: '10px' }}><strong style={{ color: "#d0021b", marginTop: '10px', marginRight: '5px', minWidth: 'fit-content' }}>{notice.title} - </strong></dt>
                                    <dd><marquee style={{ color: "#d0021b", width: '99.5%', marginTop: '10px', minWidth: '75vw' }} behavior="scrolling" direction="left" scrollamount='5'>
                                        {/* Render notice message */}
                                        {notice.message}
                                    </marquee> </dd>
                                </dl>
                            </div>
                            <hr style={{ margin: '0' }} />
                        </div>
                    ))}
                <div className="flexRow" style={{ width: "100%", padding: 0, margin: 0 }}>
                    <label className="section-heading text-center" style={{marginBottom:0}}>What Would You Like To Do?</label>
                </div>
            </div>
        );
    }

    _renderMenuTiles() {
        let {products} = this.props;
        const {isSBStaff} = this.state;
        const userImpersonation = this.props.impersonation;
        const hasProducts = _.size(products) > 0;
        const style = {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start"
        };


        // Function to move the "Shari’ah Business Current Account" to the desired position
        const reorderProducts = () => {
            const targetName = "Shari’ah Business Current Account";
            const destinationName = "Business Current Account";

            const targetIndex = products.findIndex(item => item.name === targetName);
            const destinationIndex = products.findIndex(item => item.name === destinationName);

            if (targetIndex !== -1 && destinationIndex !== -1) {
            const [movedObject] = products.splice(targetIndex, 1);
            products.splice(destinationIndex + 1, 0, movedObject);
            }
        };

        reorderProducts(); // Call the function to reorder the products

          

        return (
            <div style={style}>
                {
                    hasProducts
                    && _.map(products, (product) => (
                        product.available &&
                            <ProductTile
                                key={product.name}
                                onChange={this.handleAcceptTsAndCs}
                                onHide={this.onHide}
                                isEnable={userImpersonation && Array.isArray(userImpersonation.products) ? userImpersonation.products.find(item => item === product.id): false}
                                product={product}
                                selectProduct={this.handleOnSelectProduct}
                                onLearnMore={(key) => this.onToggleMoreInfoPopUps(MORE_INFO[key])}
                                isSBStaff={isSBStaff}
                                checkImpersonation={userImpersonation} />
                        )
                    )
                }

                {/* <MenuOptionCard
                    id="option5"
                    onClickOption={(e) => this.viewMenuOption(e, menuRoutes.newEntity)}
                    title="Onboard New Subsidiary/Entity"
                    description="The function allows you to load new subsidiaries to your existing organisational structure within 3 easy steps."
                    isEnabled={true}
                />

                <MenuOptionCard
                    id="option7"
                    styleClass="menu-tile-btn-secondary"
                    onClickOption={(e) => this.viewMenuOption(e, menuRoutes.maintenance)}
                    title="Maintenance"
                    description="The initial functionality will allow you to maintain your active account signatories.
                    This tile is being discontinued, please use the 'Signatory Maintenance' Tile going forward."
                    isEnabled={true}
                />

                <MenuOptionCard
                    id="option6"
                    styleClass="menu-tile-btn-secondary"
                    onClickOption={(e) => this.viewMenuOption(e, menuRoutes.reporting)}
                    title="Reporting"
                    description="A quick and easy reporting tool for generating adhoc  1. Account Confirmation Letters as well as  2. Account Signatory “Audit” Reports."
                    isEnabled={true}
                />

                <MenuOptionCard
                    id="option9"
                    styleClass="menu-tile-btn-secondary"
                    onClickOption={(e) => this.viewMenuOption(e, menuRoutes.electronicIndemnity)}
                    title="Electronic Indemnity"
                    description="Fill the indemnity form online and submit it at your ease."
                    isEnabled={true}
                    showApplyNow={true}
                    isComingSoon={false}
                /> */}

            </div>
        )
    }

    _renderRightPane() {
        const {applications , signatoriesApplications } = this.props;

        const {isFetchingApplications, isDoneFetchingApplications, filteredApplications, pendingFilteredApplications,signatoryData} = this.state;
        const applicationList = filteredApplications ? filteredApplications : applications;
        const PendingApplicationList = pendingFilteredApplications ? pendingFilteredApplications : applications;
        
        // const signatoriesList = signatoryData ? signatoryData : signatoriesApplications;
        const hasApplications = _.size(applications) > 0;
        return (
            <div className="page-right-pane" style={{backgroundColor:"transparent", boxShadow:"none"}}>
                <div className="page-right-pane-application" style={{ width:'100%', backgroundColor:'white', boxShadow:"0 2px 2px 0 rgba(0, 51, 170, 0.2)" }}>
                    <div className="product-heading" style={{ marginLeft: "1em", marginTop: "0.5em", padding: 0, textAlign:'left'}}>Applications
                    </div>
                    <div className="title-gradient" style={{ marginLeft: "1.2em" }} />

                    <div className="card-header" style={{ width: "100%" }}>
                        <div style={{ flex: 1 }} />
                        <ListSearchComponent
                            onFilter={this.onHandleFilterApplications}
                            placeholder="Find application..."
                        />
                    </div>
                    <div className="list-scroller-container" style={{ backgroundColor: "transparent", height: "50vh" }}>
                        {hasApplications ?
                            _.map(applicationList, application => {
                                const status = application.applicationStatus.applicationOutcome;
                                return (
                                    <div key={`application/${application.id}`} className="selection-list-item"
                                        style={{ justifyContent: "space-between", borderBottom:'0.8px solid rgb(227 227 227 / 40%)'}}

                                        // onClick={() => {
                                        //     if (application.applicationType === 'SIGNATORY_MAINTENANCE') {
                                        //         this.handleApplicationPopup({ selectedApplication: application })
                                        //             .then(() => {
                                        //                 if (this._isMounted) {
                                        //                     this.setState({ selectedApplication: application });
                                        //                 }
                                        //             })
                                        //             .catch(error => {
                                        //                 console.error('Error handling signatory maintenance application:', error);
                                        //             });
                                        //     } else {
                                        //         this._isMounted = true; // Set _isMounted to true
                                        //         this.setState({ selectedApplication: application });
                                        //     }
                                        // }}


                                        onClick={() => this._isMounted && this.setState({ selectedApplication: application })}
                                    >
                                        <div className="flexColumn"
                                            style={{ padding: 0, margin: 0, alignItems: "flex-start", placeSelf: 'flex-start' }}>
                                            <span style={{ textAlign:'left' }}>{`${application.id}`}</span>
                                            <span style={{ textAlign:'left' }}>{` ${APPLICATION_TYPE_FORMATTED[application.applicationType]}`}</span>
                                        </div>
                                        <div className="flexColumn"
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                justifyContent: "space-between",
                                                alignItems: "flex-end",
                                                maxWidth:'41%'
                                            }}>
                                            <span className="greyText" style={{ textAlign:'right' }}>{` ${STATUS_FORMATTED[status]}`}</span>
                                            <span className="greyText" style={{ textAlign:'right' }}>{` ${application.createdAt}`}</span>
                                        </div>
                                    </div>
                                )
                            }
                            )
                            : (isDoneFetchingApplications && <div>You Have No Applications!</div>)
                        }
                    </div>
                </div>
                <hr style={{ margin: '20px 0px 20px 0px' }} />

                <div className="page-right-pane-pending-application" style={{ width:'100%', backgroundColor:'white', boxShadow:"0 2px 2px 0 rgba(0, 51, 170, 0.2)"}}>
                    <div className="product-heading"
                        style={{ marginLeft: "1em", marginTop: "0.5em", padding: 0, textAlign:'left' }}>Applications Pending for Approval
                    </div>
                    <div className="title-gradient" style={{ marginLeft: "1.2em" }} />

                    <div className="card-header" style={{ width: "100%" }}>
                        <div style={{ flex: 1 }} />
                        <ListSearchComponent
                            onFilter={this.onHandleFilterPendingForApplications}
                            placeholder="Find application..."
                        />
                    </div>
                    <div className="list-scroller-container" style={{ backgroundColor: "transparent", height: "50vh" }}>
                        { hasApplications ? ( PendingApplicationList
                            .filter(application => application.applicationStatus.applicationOutcome === 'PENDING_APPROVAL')
                            .map(application => {
                                const status = application.applicationStatus.applicationOutcome;
                                
                                return (
                                    <div key={`application/${application.id}`} className="selection-list-item" style={{ justifyContent: "space-between", borderBottom:'0.8px solid rgb(227 227 227 / 40%)' }}
                                        onClick={() => this._isMountedPandingApplication && this.setState({ selectedPendingApplication: application })}>
                                        <div className="flexColumn" style={{ padding: 0, margin: 0, alignItems: "flex-start" , placeSelf: 'flex-start'}}>
                                            <span style={{ textAlign:'left' }}>{`${application.id}`}</span>
                                            <span style={{ textAlign:'left' }}>{` ${APPLICATION_TYPE_FORMATTED[application.applicationType]}`}</span>
                                        </div>
                                        <div className="flexColumn" style={{ padding: 0, margin: 0, justifyContent: "space-between", alignItems: "flex-end", maxWidth:'41%'}}>
                                            <span className="greyText" style={{ textAlign:'right' }}>{` ${STATUS_FORMATTED[status]}`}</span>
                                            <span className="greyText" style={{ textAlign:'right' }}>{ new Date(application.createdAt).toLocaleDateString('en-GB',{day:'2-digit', month:'short',year:'numeric', hour:'2-digit',minute:'2-digit',second:'2-digit',hour12:true})}</span>
                                        </div>
                                    </div>
                                );
                            })
                            )
                            : (isDoneFetchingApplications && <div>You Have No Pending Applications!</div>)
                        }
                    </div>
                </div>


                {
                    isFetchingApplications &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444" />
                    </div>
                }

            </div>
        )
    }

    _renderSelectedApplication() {
        const {selectedApplication} = this.state;
        const {signatoriesApplications } = this.props;    
        if (!selectedApplication) return null;
        return (
            <Popup onClose={() => this._isMounted && this.setState({selectedApplication: null})}>
                <Application
                    application={selectedApplication}
                    title={` ${APPLICATION_TYPE_FORMATTED[selectedApplication.applicationType]}`}
                    onContinue={this.handleOnContinueApplication}
                    onDownloadReport={this.handleDownloadReport}
                    key={selectedApplication.id}
                    signatoriesApplications={signatoriesApplications}
                />
            </Popup>
        )
    }

    _renderSelectedPandingApplication() {
        const {selectedPendingApplication} = this.state;
        const {signatoriesApplications, user} = this.props;
        if (!selectedPendingApplication) return null;
        return (
            <Popup onClose={() => this._isMountedPandingApplication && this.setState({selectedPendingApplication: null})}>
                <PandingApplication
                    application={selectedPendingApplication}
                    title={` ${APPLICATION_TYPE_FORMATTED[selectedPendingApplication.applicationType]}`}
                    acceptApp={this.handleAcceptApplication}
                    rejectApp={this.handleRejectApplication}
                    userLoggedIn ={user}
                    onDownloadReport={this.handleDownloadReport}
                    key={selectedPendingApplication.id}
                    signatoriesApplications={signatoriesApplications}
                />
            </Popup>
        )
    }

    _renderSBStaffMessage() {
        const {showSBStaffUserMessage} = this.state;
        if (!showSBStaffUserMessage) return null;
        return (
            <ImpersonationRequiredMessage onClose={this.handleToggleSBStaffUserMessage}/>
        )
    }

    _renderMoreInfoPopUps() {
        const {moreInfo} = this.state;
        if (moreInfo[MORE_INFO['Business Current Account']]) {
            return (
                <Popup onClose={() => this.onToggleMoreInfoPopUps(MORE_INFO['Business Current Account'])}>
                    <BusinessAccountInfo/>
                </Popup>
            )
        }
        else if (moreInfo[MORE_INFO['KYC Review']]) {
            return (
                <Popup onClose={() => this.onToggleMoreInfoPopUps(MORE_INFO['KYC Review'])}>
                    <KycReviewInfo/>
                </Popup>
            )
        } else if (moreInfo.gpi) {
            return (
                <Popup onClose={() => this.onToggleMoreInfoPopUps(MORE_INFO.gpi)}>
                    <GlobalPaymentsTrackingInfo/>
                </Popup>
            )
        } else if (moreInfo.globalMarket) {
            return (
                <Popup onClose={() => this.onToggleMoreInfoPopUps(MORE_INFO.globalMarket)}>
                    <GlobalMarketAccountInfo/>
                </Popup>
            )
        }
        return null;
    }

    render() {
        const {dataPending} = this.state;
        const {loading} = this.props;
        if (loading || dataPending) return null;

        return (
            <div>
                <div className="page-container" style={{backgroundColor: "#fafafa"}}>
                    {this._renderHeader()}
                    <div className="home-content-container">
                        <div className="page-main-section" style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flex: 1,
                            marginTop: "0.3em"
                        }}>
                            {this._renderMenuTiles()}
                        </div>
                        {this._renderRightPane()}
                    </div>
                </div>
                {this._renderSelectedApplication()}
                {this._renderSelectedPandingApplication()}
                {this._renderSBStaffMessage()}
                {this._renderMoreInfoPopUps()}
                <Modal show={this.state.onehubBanner} bsSize={'lg'}
                       onHide={() => this.setState({onehubBanner: false})}>
                    <Modal.Header closeButton>
                        <h4 className={'text-center'}>
                            <span className={"text-primary"}>Access to the Digital Opening App is Moving
                                                    to <span
                                className={'text-info'}>OneHub</span> from 1 October 2021.</span>
                        </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <span className={'text-center'}>
                            <p style={{TextAlign:'center'}}>We have extended the registration deadline to <span className={"text-primary"}>31 October 2021</span>.</p>
                            <p>Please email <a href="mailto:onehub@standardbank.co.za">onehub@standardbank.co.za</a> or visit <a href="https://onehub.standardbank.com/s/">OneHub (standardbank.com)</a> in order to register for the platform.</p>
                            <p>Join our award winning platform OneHub to discover many other innovative digital solutions.</p>
                        </span>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.noProductAccess} onHide={() => this.setState({noProductAccess: false})} >
                    <Modal.Header closeButton>
                        <h4 className={'text-center'}>
                            <span className={'text-danger'}>Access denied.</span>
                        </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <span className={'text-center'}>
                            <p>You have not been given access to this function. </p>
                            <p>Please request for access from the
                            person you're acting on behalf of.</p>
                        </span>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const MenuOptionCard = (props) => {
    const {id, onClickOption, title, styleClass, description, showApplyNow, isEnabled, isComingSoon, onLearnMore} = props;


    return (
        <div
            className="product-btn"
            id={id}
        >
            <div className="product-heading">
                {title}
            </div>
            <div className="title-gradient"/>
            {
                description &&
                <p style={{
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    fontSize: "12px",
                    color: "#858D9D"
                }}>{description}</p>
            }
            <div style={{flex: 1}}/>
            <div className="flexRow" style={{alignItems: "flex-end", margin: 0, padding: 0}}>
                {
                    onLearnMore &&
                    <a className="flat-button-primary-light" style={{padding: 0}}
                       onClick={onLearnMore}
                       role="button"><span>Learn More</span>
                    </a>
                }
                <div style={{flex: 1}}/>
                {
                    isComingSoon ?
                        <a className="flat-button-primary" style={{padding: 0}}><span>Coming Soon</span></a> :
                        isEnabled ?
                            <a className="flat-button-primary" style={{padding: 0}}
                               onClick={onClickOption}
                               role="button">
                                {


                                    showApplyNow ? <span>Apply Now <i className="fa fa-angle-right fa-lg"/> </span> :
                                        <i className="fa fa-arrow-right"/>


                                }
                            </a>
                            :
                            <a className="flat-button-primary" style={{padding: 0}}>
                                <span> Currently Unavailable </span>
                            </a>
                }
            </div>
        </div>
    );
};

function mapStateToProps({product, loading, importantNotice, applications, signatory ,user, impersonation}, ownProps) {
    return {
        loading,
        products: product && product.products ? product.products : [],
        applications: applications && applications.applications ? applications.applications : [],
        importantNotices: importantNotice && importantNotice.importantNotices ? importantNotice.importantNotices : [],
        signatoriesApplications: signatory && signatory.signatories ? signatory.signatories : [],
        user: user && user.user ? user.user : {},
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {}
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...actions,
            loadUserApplications,
            loadSelectedChequeApplication,
            fetchSelectedOnboardRequest,
            fetchSelectedCustodyRequest,
            fetchSelectedKYCRequest,
            fetchSelectedBolOnboardRequest,
            fetchSelectedTPFARequest,
            downloadReport,
            getSignatoryApplication,
            getSignatoryApplicationsDashboard,
            trackData,
            fetchSelectedCashManagedFundRequest,
            fetchSelectedCustodyManagedFundRequest,
            fetchSelectedCashAndCustodyManagedFundRequest,
            fetchSelectedElectronicIndemnityRequest,
            fetchShariahBusinessCurrentAccountRequest,
            fetchSavingsAccountRequest,
            approveApplication,
            rejectApplication,
            loadImportantNotices
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
