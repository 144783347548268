import React, {useState} from 'react';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import ReactLoading from 'react-loading';


export const PandingApplication = (props) => {
    const {application, userLoggedIn, acceptApp, rejectApp } = props;
    console.log(props);
    return (
        <div className={props.styleClass}>
            <div className="flexColumn" style={{ flex: 1, alignItems: 'flex-start' }}>
                <div className="product-heading">
                    Please Capture Your Response
                </div>
                <div className="title-gradient" />

                <div style={{ width: '100%', minHeight:'20vh'}}>
                    <div className="flexRow" style={{ justifyContent: 'space-between', marginTop: '1em' }}>
                        <p>Audit Report (Client can preview or download report)    <i className="fa fa-download fa-lg" /></p>
                    </div>
                </div>

                {/* <div className="action-button-container" style={{ width: '100%' }}> */}
                <div className="btn-group dropup">
                    <a className="btn btn-success p-10 mr-10" style={{ borderRadius: '20px'}}
                           onClick={(event) => acceptApp(event, application.id, userLoggedIn.identityNumber)}
                        role="button"
                    ><span> <i className="fa fa-check-circle-o fa-lg p-5" style={{margin:'0 5px',}}/>Accept   </span></a>
                    <a className="btn btn-danger p-10 ml-10" style={{marginLeft:'10px', borderRadius: '20px'}}
                           onClick={(event) => rejectApp(event, application.id, userLoggedIn.identityNumber)}
                        role="button"
                    ><span> <i className="fa fa-times-circle-o fa-lg " style={{margin:'0 5px'}} />Reject   </span></a>
                </div>
            </div>
        </div>
    )

};

export default PandingApplication;